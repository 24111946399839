import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerStreetMutation,
  ChangePlayerStreetMutationVariables,
} from './__generated__/ChangePlayerStreetMutation';
import { PlayerStreetFormQuery } from './__generated__/PlayerStreetFormQuery';
import { PlayerStreetFormStaticQuery } from './__generated__/PlayerStreetFormStaticQuery';

const query = graphql`
  query PlayerStreetFormStaticQuery {
    sanityPlayerStreetForm {
      title {
        ...LocaleString
      }
      street {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerStreetFormQuery($playerId: ID!) {
    player(playerId: $playerId) {
      id
      city
      countryCode
      street
      zipCode
    }
  }
`;

const playerMutation = gql`
  mutation ChangePlayerStreetMutation(
    $playerId: ID!
    $city: String!
    $countryCode: String!
    $street: String!
    $zipCode: String!
  ) {
    changeAddress(
      playerId: $playerId
      city: $city
      countryCode: $countryCode
      street: $street
      zipCode: $zipCode
    ) {
      id
      city
      countryCode
      street
      zipCode
    }
  }
`;

const PlayerStreetForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData = useStaticQuery<PlayerStreetFormStaticQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerStreetForm;

  const [{ data }] = useQuery<PlayerStreetFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [updatePlayerState, updatePlayer] = useMutation<
    ChangePlayerStreetMutation,
    ChangePlayerStreetMutationVariables
  >(playerMutation);

  const defaultValues = {
    street: data?.player?.street,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (!data?.player?.id || !values.street) {
      return;
    }

    updatePlayer({
      playerId: data?.player.id,
      street: values.street,
      city: data.player.city ?? '',
      countryCode: data.player.countryCode ?? '',
      zipCode: data.player.zipCode ?? '',
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <TextField
            title={t(form.street)}
            name="street"
            id="PlayerStreetForm_street"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={updatePlayerState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerStreetForm;
